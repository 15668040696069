import React, { useEffect } from "react"
import { navigate } from "@reach/router"
import { Container } from "src/styled"

export default () => {
    useEffect(() => {
        redirectUser()
    }, [])

    function redirectUser() {
        setTimeout(() => {
            navigate("/")
        }, 10000)
    }

    return (
        <Container textAlign="center">
            Dziękujemy za złożenie zamówienia. Czekaj na telefon od konsultanta,
            który zadzwoni, aby potwierdzić godzinę dostawy.
        </Container>
    )
}
